import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Loader from "../components/loader"
import { connect } from "react-redux"
import defaultImage from "../assets/images/default.png"
import { FormattedMessage } from "react-intl"
import api from "../_api/dataApi"
import { error } from "../utils"
import { Box } from "@material-ui/core"

const Terms = ({ lang }) => {
  const [content, setContent] = useState("")
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    api
      .getConstantPages("privacy-policy")
      .then(res => {
        setContent(res?.content)
      })
      .catch(() => error())
      .finally(() => setLoading(false))
  }, [lang])

  return (
    <Layout>
      <SEO title="Terms & Conditions" />
      {loading && <Loader />}
      <div className="py-3">
        <Box className="TermsAndConditionsMainBox">
            <div className="signInBorder d-flex">
              <span
                className="font-black px38 mx-3 TermsAndConditions"
              >
                <FormattedMessage defaultMessage="Terms & Conditions" />
              </span>
            </div>
        </Box>

        <Box
        className="imageAndTextBox"
        >
          <div className="container py-lg-3 py-2 pleaseReadOurAndText">
            <p className="font-semi-bold black pleaseReadOurTAndC">
              <FormattedMessage defaultMessage="Please read our terms & conditions carefully" />
            </p>
            <div
              className="darkGrey my-lg-5 my-3 text-start TermsAndConditionsText"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
          <div
            className="py-lg-4 pt-5 text-center imageTermsBox"
          >
            <img
              alt="aboutUs"
              src={defaultImage}
              className="imageTermsAndConditions"
            />
          </div>
        </Box>
      </div>
    </Layout>
  )
}

const mapStateToProps = ({ settings: { lang } }) => ({ lang })
export default connect(mapStateToProps)(Terms)
